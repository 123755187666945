<template>
  <div class="oAuthAuthorizer">
    <img class="loader" :src="require('@/assets/loader.gif')" alt="" />
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "oAuthAuthorizer",
  computed: {
    defaultRedirectUrl() {
      return this.$route.query?.source || `${window.location.origin}/home`;
    },
  },
  methods: {
    ...mapActions(["authorizeOAuth"]),
  },
  async created() {
    let { token, source, auth_type, new_user } = this.$route.query;

    // facebook authorization variables
    let { state, oauth_token, email, first_name, last_name, error } =
      this.$route.query;

    if (error) console.log(error, "Connection error account_exist");

    // facebook: account_exist
    if (error === "account_exist") {
      return this.$router.push({
        name: "formSignIn",
        params: { email },
        query: { redirect: source },
      });
    }

    // facebook: email is missing
    if (state && state === "social_sign_up") {
      return this.$router.push({
        name: "formSignUpFacebook",
        query: { first_name, last_name, oauth_token, redirect: source },
      });
    }

    if (!token) {
      window.location = this.defaultRedirectUrl;
      return;
    }

    try {
      const redirect = await this.authorizeOAuth({
        token: token,
        redirect: source || localStorage.getItem("accountRedirect"),
        authType: auth_type,
        newUser: new_user, // $route query params returns boolean values as string
      });
      window.location = redirect;
    } catch (e) {
      console.log("####");
      console.error(e);
    }
  },
};
</script>

<style lang="scss" scoped>
.oAuthAuthorizer {
  --toolbar-height: 80px;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  align-items: center;
  justify-content: center;
  height: calc(100vh - (var(--toolbar-height) + 5px));

  @media screen and (max-width: 1279px) {
    --toolbar-height: 65px;
  }
  .loader {
    height: 80px;
    width: 80px;
  }
}
</style>
